import { format, parse } from 'date-fns'

export const PREFILL_JOURNEY_DATA = 'form//PREFILL_JOURNEY_DATA'
export const SET_STEP_DATA = 'form//SET_STEP_DATA'
export const RESET_FORM_DATA = 'form//RESET_FORM_DATA'

export const SET_DATA_MODELS = 'form//SET_DATA_MODELS'

export function prefillJourneyData(journeyData, carModels) {
  const convertDate = date => {
    if (!date) {
      return null
    }
    return format(parse(date), 'DD/MM/YYYY')
  }
  const mapCarName = carName => {
    const matchingModel = carModels.find(
      model => model.label.toLowerCase() === carName.toLowerCase()
    )
    return matchingModel?.value ?? null
  }
  const contactData = journeyData.customerData?.contactData
  const defaultValue = ''

  return {
    type: PREFILL_JOURNEY_DATA,
    payload: {
      1: {
        carModel: mapCarName(journeyData.vehicleData?.vehicleModel?.name) ?? '',
        carRegistrationDate:
          convertDate(journeyData.vehicleData?.initialRegistrationDate) ??
          defaultValue,
        carCurrentMileage: journeyData.vehicleData?.odometer ?? defaultValue,
      },
      3: {
        salutation: journeyData.customerData?.salutation ?? defaultValue,
        firstName: journeyData.customerData?.firstName ?? defaultValue,
        lastName: journeyData.customerData?.lastName ?? defaultValue,
        nifNumber: journeyData.customerData?.NIF ?? defaultValue,
        dateOfBirth:
          convertDate(journeyData.customerData?.dateOfBirth) ?? defaultValue,
        placeOfBirth: journeyData.customerData?.stateOfBirth ?? defaultValue,
        email: journeyData.customerData?.contactData?.email ?? defaultValue,
        confirmEmail:
          journeyData.customerData?.contactData?.email ?? defaultValue,
        phoneNumber:
          contactData?.mobilePhoneNumber && contactData?.mobilePhoneCountryCode
            ? `${contactData.mobilePhoneCountryCode}${contactData.mobilePhoneNumber}`
            : defaultValue,
        street: journeyData.customerData?.address?.street ?? defaultValue,
        houseNumber:
          journeyData.customerData?.address?.houseNumber ?? defaultValue,
        postalCode: journeyData.customerData?.address?.zipCode ?? defaultValue,
        city: journeyData.customerData?.address?.city ?? defaultValue,
        vin: journeyData.vehicleData?.vin ?? defaultValue,
        licPlate: journeyData.vehicleData?.licensePlate ?? defaultValue,
      },
      4: {
        iban: journeyData.customerData?.paymentData?.iban ?? defaultValue,
      },
    },
  }
}

export function setStepData(step, data) {
  return {
    type: SET_STEP_DATA,
    payload: {
      step,
      data,
    },
  }
}

export function setDataModels(data) {
  return {
    type: SET_DATA_MODELS,
    payload: data,
  }
}

export function getDataModels(store) {
  return store.form.dataModels
}

export function resetFormData() {
  return {
    type: RESET_FORM_DATA,
  }
}

export function getStepData(store, step) {
  return store.form.stepData[step]
}

export function getFormData(store) {
  return store.form
}

const initialState = {
  stepData: {},
  dataModels: {},
}

export default function(state = initialState, action) {
  switch (action.type) {
    case RESET_FORM_DATA:
      return {
        ...state,
        stepData: {},
      }

    case PREFILL_JOURNEY_DATA:
      // eslint-disable-next-line no-case-declarations
      const prefilledStepsData = Object.entries(action.payload).reduce(
        (agg, [stepNumber, stepData]) => {
          return {
            ...agg,
            [stepNumber]: {
              ...state.stepData[stepNumber],
              ...stepData,
            },
          }
        },
        { ...state.stepData }
      )
      return {
        ...state,
        stepData: prefilledStepsData,
      }

    case SET_STEP_DATA:
      return {
        ...state,
        stepData: {
          ...state.stepData,
          [action.payload.step]: action.payload.data,
        },
      }

    case SET_DATA_MODELS:
      return {
        ...state,
        dataModels: action.payload,
      }

    default:
      return state
  }
}
