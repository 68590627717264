export const FETCH_GET_PRICING_PENDING = 'pricing//FETCH_GET_PRICING_PENDING'
export const FETCH_GET_PRICING_DONE = 'pricing//FETCH_GET_PRICING_DONE'
export const FETCH_GET_PRICING_ERROR = 'pricing//FETCH_GET_PRICING_ERROR'

export function pending() {
  return {
    type: FETCH_GET_PRICING_PENDING,
  }
}

export function done(data) {
  return {
    type: FETCH_GET_PRICING_DONE,
    payload: {
      data,
    },
  }
}

export function error(err) {
  return {
    type: FETCH_GET_PRICING_ERROR,
    payload: {
      err,
    },
  }
}

export function getPricingData(store) {
  return store.pricing.data || {}
}

const initialState = {
  pending: false,
  data: {},
  error: null,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_GET_PRICING_PENDING:
      return {
        ...state,
        data: {},
        pending: true,
        error: null,
      }

    case FETCH_GET_PRICING_DONE:
      return {
        ...state,
        data: action.payload.data,
        pending: false,
        error: null,
      }
    case FETCH_GET_PRICING_ERROR:
      return {
        ...state,
        data: {},
        pending: false,
        error: true,
      }

    default:
      return state
  }
}
