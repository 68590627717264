import { getBrandName } from '../../config'

/**
 * The base resource needs to be declared in this file and not imported,
 * because it must resolve to a string directly, otheriwse it will not work in
 * the getPages and getModels methods.
 */
const BASE_RESOURCE = 'base'

export const getPages = brand => {
  return import(`../../resources/${brand}/pages/index.json`).catch(() => {
    return import(`../../resources/${BASE_RESOURCE}/pages/index.json`)
  })
}

export const getModels = brand => {
  return import(`../../resources/${brand}/models`).catch(() => {
    return import(`../../resources/${BASE_RESOURCE}/models`)
  })
}

export const getImageForModel = (brandName, model, carModelImages) => {
  return `${process.env.PUBLIC_URL}/assets/${brandName}/silhouettes/${carModelImages[model]}.svg`
}

export const getLogosSrc = async brandName => {
  let logoDefault
  let logoSmall

  switch (brandName) {
    case 'skoda':
    case 'vw':
      logoDefault = await import(
        `@vwfs-bronson/bronson-${brandName}/dist/img/logo.png`
      )
      logoSmall = await import(
        `@vwfs-bronson/bronson-${brandName}/dist/img/logo-small-screen.png`
      )

      return {
        logoDefault: logoDefault.default,
        logoSmall: logoSmall.default,
      }
    case 'vw6':
    case 'seat':
    case 'audi':
      logoDefault = await import(
        `@vwfs-bronson/bronson-${brandName}/dist/img/logo.svg`
      )

      return {
        logoDefault: logoDefault.default,
        logoSmall: logoDefault.default,
      }
    case 'vwcv':
    default:
      logoDefault = await import('@vwfs-bronson/bronson-vw/dist/img/logo.png')
      logoSmall = await import(
        '@vwfs-bronson/bronson-vw/dist/img/logo-small-screen.png'
      )

      return {
        logoDefault: logoDefault.default,
        logoSmall: logoSmall.default,
      }
  }
}

export function shouldUseVerticalBar(brandName = getBrandName()) {
  return ['vw', 'vwcv', 'vwfs'].includes(brandName)
}
