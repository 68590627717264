import { createStore, combineReducers } from 'redux'

import formReducer from './features/form'
import pricingReducer from './features/pricing.redux'
import storeFrontIdReducer from './features/premium-selection.redux'

/* eslint-disable no-underscore-dangle */
const store = createStore(
  combineReducers({
    form: formReducer,
    pricing: pricingReducer,
    storeFrontId: storeFrontIdReducer,
  }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)
/* eslint-enable */

export { store }
