import * as Yup from 'yup'

import {
  addDays,
  differenceInWeeks,
  differenceInYears,
  format as fnsFormat,
  isFuture,
  isPast,
} from 'date-fns'

import { setDateFromWeekNumber } from './format'

Yup.addMethod(Yup.string, 'dateFormat', function format(
  dateFormatSeparator,
  formats,
  message
) {
  // need to be string for custom error message
  return this.test('testFormat', message, function testFormat(value) {
    if (value !== undefined) {
      const date = value.split(dateFormatSeparator)
      return (
        value === fnsFormat(new Date(date[2], date[1] - 1, date[0]), formats)
      )
    }
    return true
  })
})
Yup.addMethod(Yup.string, 'differenceWeeks', function differenceWeeks(
  dateFormatSeparator,
  weeks,
  message
) {
  // need to be string for custom error message
  return this.test('testWeeks', message, function testWeeks(value) {
    if (value !== undefined) {
      const date = value.split(dateFormatSeparator)
      return (
        differenceInWeeks(
          new Date(),
          new Date(date[2], date[1] - 1, parseInt(date[0], 10) + 1)
        ) < weeks
      )
    }
    return true
  })
})
Yup.addMethod(Yup.string, 'notInFuture', function notInFuture(
  dateFormatSeparator,
  message
) {
  // need to be string for custom error message
  return this.test('testAge', message, function testAge(value) {
    if (value !== undefined) {
      const date = value.split(dateFormatSeparator)
      return !isFuture(addDays(new Date(date[2], date[1] - 1, date[0]), 1))
    }
    return true
  })
})
Yup.addMethod(Yup.string, 'notInPast', function notInPast(
  dateSeperator,
  message
) {
  // need to be string for custom error message
  return this.test('testDateNotInPast', message, function testDateNotInPast(
    value
  ) {
    if (value !== undefined) {
      const dateArr = value.split(dateSeperator)
      const date = addDays(new Date(dateArr[2], dateArr[1] - 1, dateArr[0]), 1)
      return !isPast(date)
    }
    return true
  })
})
Yup.addMethod(Yup.string, 'validWeekOfDelivery', function validWeekOfDelivery(
  dateFormatSeparator,
  message
) {
  // need to be string for custom error message
  return this.test('testAge', message, function testAge(value) {
    if (value !== undefined && value.length === 7) {
      const date = value.split(dateFormatSeparator)
      const dataFromWeekNumber = setDateFromWeekNumber(value)
      return (
        parseInt(date[0], 10) > 0 &&
        parseInt(date[0], 10) < 53 &&
        differenceInYears(dataFromWeekNumber, new Date()) < 1
      )
    }
    return true
  })
})
Yup.addMethod(
  Yup.string,
  'notInPastMoreThenYears',
  function notInPastMoreThenYears(dateFormatSeparator, years, message) {
    // need to be string for custom error message
    return this.test('testAge', message, function testAge(value) {
      if (value !== undefined) {
        const date = value.split(dateFormatSeparator)
        return (
          differenceInYears(
            new Date(),
            addDays(new Date(date[2], date[1] - 1, date[0]), 1)
          ) < years
        )
      }
      return true
    })
  }
)
Yup.addMethod(
  Yup.string,
  'notInPastMoreThenYearsFromWarrantyStart',
  function notInPastMoreThenYearsFromWarrantyStart(
    dateFormatSeparator,
    years,
    dateWarrantyStart,
    message
  ) {
    // need to be string for custom error message
    return this.test('testAge', message, function testAge(value) {
      if (value !== undefined && dateWarrantyStart !== undefined) {
        const date = value.split(dateFormatSeparator)
        const dateWarrantyStartSplited = this.parent[dateWarrantyStart].split(
          dateFormatSeparator
        )
        return (
          differenceInYears(
            new Date(
              dateWarrantyStartSplited[2],
              dateWarrantyStartSplited[1] - 1,
              dateWarrantyStartSplited[0]
            ),
            new Date(date[2], date[1] - 1, date[0])
          ) < years
        )
      }
      return true
    })
  }
)
Yup.addMethod(
  Yup.string,
  'notLessThenYearsFromRegistrationDate',
  function notLessThenYearsFromRegistrationDate(
    dateFormatSeparator,
    years,
    carRegistrationDate,
    message
  ) {
    // need to be string for custom error message
    return this.test('testAge', message, function testAge(value) {
      if (value !== undefined && carRegistrationDate !== undefined) {
        const date = value.split(dateFormatSeparator)
        const dateWarrantyStartSplited = this.parent[carRegistrationDate].split(
          dateFormatSeparator
        )
        return (
          differenceInYears(
            new Date(date[2], date[1] - 1, date[0]),
            new Date(
              dateWarrantyStartSplited[2],
              dateWarrantyStartSplited[1] - 1,
              dateWarrantyStartSplited[0]
            )
          ) >= years
        )
      }
      return true
    })
  }
)
Yup.addMethod(Yup.string, 'noLeadingWhitespace', function noLeadingWhitespace(
  message
) {
  return this.test('testFormat', message, function testFormat(value) {
    if (!value) {
      return true
    }
    return value.match(/^[^\s].*$/)
  })
})
Yup.addMethod(Yup.string, 'fullAge', function fullAge(
  years,
  dateSeperator,
  message
) {
  // need to be string for custom error message
  return this.test('testAge', message, function testAge(value) {
    if (value) {
      const date = value.split(dateSeperator)
      return (
        differenceInYears(
          new Date(),
          new Date(date[2], date[1] - 1, date[0])
        ) >= years
      )
    }
    return true
  })
})
Yup.addMethod(Yup.string, 'equalEmails', function equalEmails(field, message) {
  return this.test('equalEmail', message, function equalEmail(value) {
    if (value) {
      return this.parent[field] === undefined || value === this.parent[field]
    }
    return true
  })
})
