import { addDays, format as fnsFormat } from 'date-fns'
import { shouldUseVerticalBar } from '../brand'

export const dateFormat = 'DD/MM/YYYY'

export const dateFormatSeparator = dateFormat.split('').find(item => {
  return !item.match(/^[a-zA-Z]*$/)
})

export const numbersFormatSeparator = '.'

export function formatInteger(number, toFixed) {
  if (number !== undefined) {
    return parseFloat(number)
      .toFixed(toFixed ? 2 : 0)
      .toString()
      .replace(/\./g, ',')
      .replace(/\D,+/g, '')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${numbersFormatSeparator}`)
  }
  return null
}

export const formatWithUnit = (val, unit, toFixed) => {
  return formatInteger(val, toFixed)
    ? `${formatInteger(val, toFixed)} ${unit}`
    : ''
}

export const formatPrice = val => {
  if (val) {
    return formatWithUnit(val.grossPrice, '€', true)
  }
  return ''
}

export function scrollToTargetAdjusted(targetElementClass) {
  const STICKY_HEADER_HEIGHT = shouldUseVerticalBar() ? 100 : 0
  const offsetPosition =
    document.querySelector(targetElementClass).getBoundingClientRect().top -
    STICKY_HEADER_HEIGHT
  if (document.documentElement.style.scrollBehavior === undefined) {
    window.scrollTo(0, offsetPosition)
  } else {
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    })
  }
}

export function getWeekNumber(_d) {
  const d = new Date(Date.UTC(_d.getFullYear(), _d.getMonth(), _d.getDate()))
  const monthIndex = d.getUTCMonth()
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7))
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
  const weekNumber = Math.ceil(((d - yearStart) / 86400000 + 1) / 7)
  return monthIndex === 0 && weekNumber >= 52 ? 1 : weekNumber
}

export function setDateFromWeekNumber(d) {
  const [week, year] = d.split('/')
  const expectedDeliveryDate = new Date(year, 0, (week - 1) * 7)
  expectedDeliveryDate.setDate(
    // set week day to friday
    expectedDeliveryDate.getDate() - expectedDeliveryDate.getDay() + 5
  )
  return expectedDeliveryDate
}

export const checkCifNumber = value => {
  const letters = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'J',
    'K',
    'L',
    'M',
    'N',
    'P',
    'Q',
    'R',
    'S',
    'U',
    'V',
    'W',
  ]
  const controlLetters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']
  const valueUpperCase = value.replace('-', '').toUpperCase()

  if (letters.indexOf(valueUpperCase.substring(0, 1)) === -1) {
    return false
  }
  const numbers = valueUpperCase.substring(1, valueUpperCase.length - 1)
  const lastChar = valueUpperCase.substr(valueUpperCase.length - 1, 1)
  let controlDigit
  if (parseInt(lastChar, 10).toString() === lastChar) {
    controlDigit = parseInt(lastChar, 10)
  } else {
    controlDigit = controlLetters.indexOf(lastChar) + 1
  }

  let sumA = 0
  let sumB = 0

  for (let x = 2; x <= 6; x += 2) {
    sumA += parseInt(numbers[x - 1], 10)
  }
  for (let x = 1; x <= 7; x += 2) {
    const item = (parseInt(numbers[x - 1], 10) * 2).toString()
    sumB =
      sumB +
      parseInt(item[0], 10) +
      (item[1] === undefined ? 0 : parseInt(item[1], 10))
  }

  const sum = sumA + sumB
  const sumsOnes = sum % 10
  return controlDigit === (10 - sumsOnes) % 10
}

export const checkNifNumber = value => {
  const letters = 'TRWAGMYFPDXBNJZSQVHLCKET'

  const valueUpperCase = value.replace('-', '').toUpperCase()

  const lastChar = valueUpperCase.substr(valueUpperCase.length - 1, 1)

  if (valueUpperCase.match(/^([0-9]{8})*[a-zA-Z]+$/)) {
    const controlNumber =
      valueUpperCase.substr(0, valueUpperCase.length - 1) % 23
    const lastCharCheck = letters.substring(controlNumber, controlNumber + 1)
    return lastCharCheck === lastChar
  }
  if (valueUpperCase.match(/^[XYZ]/)) {
    const startChar = ['X', 'Y', 'Z']
    const mapNumber = ['0', '1', '2']
    let numbers = valueUpperCase
    for (let i = 0; i < startChar.length; i += 1) {
      numbers = numbers.replace(startChar[i].toUpperCase(), mapNumber[i])
    }
    numbers = numbers.substr(0, valueUpperCase.length - 1) % 23
    const lastCharCheck = letters.substring(numbers, numbers + 1)
    return lastCharCheck === lastChar
  }
  if (valueUpperCase.match(/^[ABCDEFGHJPQRSUVNW]/)) {
    let pairs = 0
    let single = 0
    let sumTotal = 0
    let controlDigit
    const initialChar = valueUpperCase.substring(0, 1)
    const codes = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']
    const numbers = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9]
    let lastX = 0
    for (let x = 2; x <= 6; x += 2) {
      pairs += parseInt(valueUpperCase.substr(x, 1), 10)
      single += numbers[parseInt(valueUpperCase.substr(x - 1, 1), 10)]
      lastX = x
    }
    single += numbers[parseInt(valueUpperCase.substr(lastX - 1, 1), 10)]
    sumTotal = pairs + single
    const units = sumTotal % 10

    if (units === 0) controlDigit = units
    else controlDigit = 10 - units

    let retVal
    switch (initialChar) {
      case 'A':
      case 'B':
      case 'E':
      case 'H':
        retVal = controlDigit === lastChar
        break
      case 'K':
      case 'P':
      case 'Q':
      case 'S':
        retVal = codes[controlDigit] === lastChar
        break
      default:
        retVal = codes[controlDigit] === lastChar || controlDigit === lastChar
        break
    }
    return retVal
  }
  if (valueUpperCase.match(/^(\(?\+?[0-9]*\)?)?[0-9_\- ()]*$/)) {
    return valueUpperCase.length <= 11
  }
  return false
}

export function calculateAgeInMonths(values) {
  const startDate = calculateStartDate(values).split(dateFormatSeparator)
  const carRegistrationDate = values.carRegistrationDate.split(
    dateFormatSeparator
  )
  return Math.ceil(
    (parseInt(startDate[2], 10) - parseInt(carRegistrationDate[2], 10)) * 12 +
      (parseInt(startDate[1], 10) - parseInt(carRegistrationDate[1], 10)) +
      (parseInt(startDate[0], 10) + 1 - parseInt(carRegistrationDate[0], 10)) /
        30.5
  )
}

export function calculateOfferAvailability(offer, values, dataModels) {
  const months = calculateAgeInMonths(values)
  const mileage = parseInt(values.carCurrentMileage, 10)

  return (
    mileage <= dataModels.carWarrantyCoverage[offer].limit &&
    months <= dataModels.carWarrantyCoverage[offer].max
  )
}

export function calculateStartDate(values) {
  const mWarrantyEndDateSplited = calculateManufacturerWarrantyEndDate(
    values
  ).split(dateFormatSeparator)

  return fnsFormat(
    addDays(
      new Date(
        parseInt(mWarrantyEndDateSplited[2], 10),
        parseInt(mWarrantyEndDateSplited[1], 10) - 1,
        mWarrantyEndDateSplited[0]
      ),
      1
    ),
    dateFormat
  )
}

const checkDateBefore01012022 = date => {
  const fechaActual = new Date()

  const enteredDate = new Date(
    parseInt(date[2], 10),
    parseInt(date[1], 10) - 1,
    parseInt(date[0], 10)
  )

  const twoYearsAgoDate = new Date()
  twoYearsAgoDate.setFullYear(fechaActual.getFullYear() - 2)
  twoYearsAgoDate.setDate(fechaActual.getDate() - 1)

  const limitDate = new Date(2022, 0, 1)

  return enteredDate >= twoYearsAgoDate && enteredDate < limitDate
}
export function calculateManufacturerWarrantyEndDate(values) {
  const isCarWarrantyExtension = () => values.carWarrantyExtension === 'yes'

  const startDateSplited = isCarWarrantyExtension()
    ? values.carWarrantyExtensionExpiration.split(dateFormatSeparator)
    : values.carRegistrationDate.split(dateFormatSeparator)

  const value =
    checkDateBefore01012022(startDateSplited) && !isCarWarrantyExtension()
      ? 2
      : 3

  return fnsFormat(
    addDays(
      new Date(
        isCarWarrantyExtension()
          ? startDateSplited[2]
          : parseInt(startDateSplited[2], 10) + value,
        parseInt(startDateSplited[1], 10) - 1,
        startDateSplited[0]
      ),
      isCarWarrantyExtension() ? 0 : -1
    ),
    dateFormat
  )
}

export function calculateEndDate(startDate) {
  const endDateSplited = startDate.split(dateFormatSeparator)
  return fnsFormat(
    addDays(
      new Date(
        parseInt(endDateSplited[2], 10) + 1,
        parseInt(endDateSplited[1], 10) - 1,
        endDateSplited[0]
      ),
      -1
    ),
    dateFormat
  )
}

export function formatISODate(date) {
  const dateSplited = date.split(dateFormatSeparator)
  return new Date(
    parseInt(dateSplited[2], 10),
    parseInt(dateSplited[1], 10) - 1,
    dateSplited[0],
    12,
    0
  ).toISOString()
}

export function formatPhoneNumberPreFill(phoneNumber) {
  if (phoneNumber) {
    const phoneNumberSplited = phoneNumber.split('')
    if (phoneNumberSplited[0] !== '+') {
      return `+${phoneNumber}`
    }
  }
  return phoneNumber
}
